
  import {  reactive, onUnmounted, onMounted, computed } from "vue"; 
  import ShowQrCode from "@/components/Shared/ShowQrCode.vue";
  import {DynamicListToDetailFormChannelForRoot} from "@/components/Shared/dynamicList/Hooks/DynamicListToDetailFormChannel"
  import { DynamicGrid, DynamicGridRow } from "@/components/Shared/dynamicList/DynamicListTypes";
  import { DynamicDetailToFormChannelForRoot, CreateFromDetailState } from "../Shared/dynamicList/Hooks/DynamicDetailFormToListChannel";
  import { useRoute } from "vue-router";
  import { DetailsSupported } from "../Shared/dynamicList/Hooks/DynamicDetailTypes";
  import { RoutedButtonBarRequestChannelForRoot, RoutedButtonBarRequestState } from "../Shared/routed-button-bar/RoutedButtonBarRequestChannel";

  export default {
    name: "PersonProduct",    
    inheritAttrs: false,
    components: {
      ShowQrCode,
     
    },
    setup(props: any, context: any) {


      const route=useRoute();    
      const clientRouteName = computed(() =>route.name?.toString())


      const receiveDetailNotfication = DynamicListToDetailFormChannelForRoot.getService();
      const sendDetailNotfication = DynamicDetailToFormChannelForRoot.getService();

      const routedButtonBarRequest = RoutedButtonBarRequestChannelForRoot.getService()
      const backState: RoutedButtonBarRequestState = {
        sourceRoute: clientRouteName.value,
        targetRoutes: {backTargetRoute: "Products" , vorwardTargetRoute: undefined},
        command: "Back",
        topic: "DynamicOnOffFilter"
        }

      routedButtonBarRequest.changeRouteState(backState)


      const _grid: DynamicGrid = {userId: "", viewId: ""}
      const _row: DynamicGridRow = {
        cell: {
          image:{},
          content: {},
          additional: {}
        }
      }
      const dynamic = reactive({
        grid: _grid,
        row: _row,
        isNotification: false,   
      })

      const qrc = reactive({
        QRCode: ""
      })

      const detailInfo = reactive({
        result: {
            personProduktNummer: "",
            QrCode: "",
            land: "",
            ort: "",
            plz: "",
            strasse: "",
            strassenummer: "",
            postfach: "",
            co: "",
            zusatz: "",
            vorname: "",
            nachname: "",
            geburtsdatum: "",
            nachnamefirmennamevorname: "",
            bezugsInformation: "",
            groundNumber: "",
            groundName: "",
        }
      })

      const detailRows = reactive({
        result: [{
            personProduktNummer: "",
            QrCode: "",
            land: "",
            ort: "",
            plz: "",
            strasse: "",
            strassenummer: "",
            postfach: "",
            co: "",
            zusatz: "",
            vorname: "",
            nachname: "",
            geburtsdatum: "",
            nachnamefirmennamevorname: "",
            bezugsInformation: "",
            groundNumber: "",
            groundName: "",
        }]
      })

      const resetValues = () => {
        detailInfo.result = {
            personProduktNummer: "",
            QrCode: "",
            land: "",
            ort: "",
            plz: "",
            strasse: "",
            strassenummer: "",
            postfach: "",
            co: "",
            zusatz: "",
            vorname: "",
            nachname: "",
            geburtsdatum: "",
            nachnamefirmennamevorname: "",
            bezugsInformation: "",
            groundNumber: "",
            groundName: "",


        }

        detailRows.result = [{
            personProduktNummer: "",
            QrCode: "",
            land: "",
            ort: "",
            plz: "",
            strasse: "",
            strassenummer: "",
            postfach: "",
            co: "",
            zusatz: "",
            vorname: "",
            nachname: "",
            geburtsdatum: "",
            nachnamefirmennamevorname: "",
            bezugsInformation: "",
            groundNumber: "",
            groundName: "",

        }]
      }

      const detailSubscription = receiveDetailNotfication.subscription().subscribe({
        next: (value) => {         
          if ( receiveDetailNotfication.isAttached(clientRouteName.value as unknown as DetailsSupported )) {   
            dynamic.grid = value.grid
            dynamic.row = value.row
            dynamic.isNotification = value.isNotification
            resetValues()

            if ( dynamic.row.jsonDetail) {                
                const rowValues = JSON.parse(dynamic.row.jsonDetail)                    
                if (  rowValues ) {                    
                    //detailInfo.result = rowValues[1];
                    detailRows.result = rowValues;


                    detailInfo.result.co =  rowValues[1].co
                    detailInfo.result.land =  rowValues[1].land
                    detailInfo.result.nachname =  rowValues[1].nachname
                    detailInfo.result.ort =  rowValues[1].ort
                    detailInfo.result.personProduktNummer =  rowValues[1].personProduktNummer
                    detailInfo.result.plz =  rowValues[1].plz
                    detailInfo.result.postfach =  rowValues[1].postfach
                    detailInfo.result.strasse =  rowValues[1].strasse
                    detailInfo.result.strassenummer =  rowValues[1].strasseNummer
                    detailInfo.result.vorname =  rowValues[1].vorname
                    detailInfo.result.zusatz =  rowValues[1].zusatz
                    detailInfo.result.geburtsdatum = rowValues[1].geburtsdatum
                    detailInfo.result.nachnamefirmennamevorname = rowValues[1].nachnamefirmennamevorname
                    detailInfo.result.bezugsInformation = rowValues[1].bezugsInformation
                 

                    qrc.QRCode = detailInfo.result.bezugsInformation + " - "
                    //qrc.QRCode = detailInfo.result.nachnamefirmennamevorname + " - " + detailInfo.result.geburtsdatum + " - "
                    
                    if ( dynamic.row.cell.content.groundNumber && dynamic.row.cell.content.groundNumber.length > 0)
                    {
                      qrc.QRCode += detailInfo.result.personProduktNummer + " - " + dynamic.row.cell.content.title + " - " + dynamic.row.cell.content.groundNumber + " " + dynamic.row.cell.content.groundName + " - " +  dynamic.row.cell.additional.text + ": " + dynamic.row.cell.additional.gueltigVon + " - "  + dynamic.row.cell.additional.gueltigBis
                    }                  
                    else {
                      qrc.QRCode += detailInfo.result.personProduktNummer + " - " + dynamic.row.cell.content.title + " - " +  dynamic.row.cell.additional.text + ": " + dynamic.row.cell.additional.gueltigVon + " - "  + dynamic.row.cell.additional.gueltigBis
                    }

                    //


                    
                }
                
            }

          }
        }
      })

      async function onClosed() {        
        sendDetailNotfication.changeDetailState(await CreateFromDetailState(dynamic.row, true, true, clientRouteName.value as unknown as DetailsSupported))
      }


      const pages = reactive({
        totalPages: dynamic.grid.pages ?? 1,
        selectedIndex: 1,
        refreshed: false,
      });

      const resetPages = () => {
        pages.selectedIndex = 1
        pages.refreshed = false
        pages.totalPages = dynamic.grid.pages ?? 1
      }



      function getImagePath( record: DynamicGridRow) {
          if ( record.cell && record.cell.image )
              return record.cell.image.imagePath ?? ''
          return ""
      }
     
      onUnmounted(() => {
        detailSubscription.unsubscribe();            
    });
     
  
      return {
    
        getImagePath,
        onClosed,
        dynamic,
        pages,
        detailInfo,
        qrc
      };
    },
  };
