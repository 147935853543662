/* eslint-disable no-console */
import { register } from "register-service-worker";
//import { ReorganizeDatabaseAfterChange } from "./infrastructure/serviceworker-related/ReorganizeDatabaseAfterChange";

const versionNumber = "001"
let _register: any = {}

export function serviceWorkerUpdate() {
  try {
    _register.update();
  }
  catch (e) {
    console.log("Service worker has been registered.");
  } 
}

if (process.env.NODE_ENV === "production") {
  register(`${process.env.BASE_URL}service-worker.js`, {
    registrationOptions: { scope: '/', type: 'module' },
    ready() {    
      console.log(`${process.env.BASE_URL}service-worker.js`)
      console.log("Service worker has been ready.");   
    },
    registered(register) {   
      console.log("Service worker has been registered.");
      _register = register
    },
    cached() {
      console.log("Content has been cached for offline use.");
    },
    updatefound() {
      console.log("New content is downloading.");
    },
    updated(registration) {    
      document.dispatchEvent(
        new CustomEvent('swUpdated', { detail: registration }))     
    },
    offline() {
      console.log(
        "No internet connection found. App is running in offline mode."
      );
    },
    error(error) {
      console.error("Error during service worker registration:", error);
    },
  })
}

