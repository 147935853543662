import { ComboBoxDataSource } from "@/components/Shared/Dynamicform/ComboBoxDataSource"
import { Version, addOrReplaceRecord, getRecord } from "../database/asyncDb"
import { ConvertCanonicalToDate, ConvertDateToIsoString, ConvertUtcStringToDateTime, GetDateDiffMinutes } from "../functional/datetimehelper"
import { ModalDialogType, openDialog } from "../observables/ModalDialogNotification"
import { Resource, ResourceText } from "../resource/resource"
import { isAllSynced } from "../serviceworker-related/Sync"
import { Out } from "../frontent/clientMessage"
import { UserProfile } from "../backend/userProfile"


export type AppVersion = {   
    isServiceWorkerUpdateAvailable: boolean

    versionUpdatedAt : string | undefined
    
    serviceWorkerUpdatedAt: string | undefined
    serviceWorkerActivatedAt?: string | undefined
    
    currentVersion: string | undefined
    newVersion: string | undefined
    
    currentDisplayVersion: string | undefined,
    newDisplayVersion: string | undefined,
   

    serviceWorkerDelayetAt: string | undefined
}

export const appVersionControl: AppVersion = {
    isServiceWorkerUpdateAvailable: false,

    versionUpdatedAt: undefined,

    serviceWorkerUpdatedAt: undefined,
    serviceWorkerActivatedAt: undefined,    

    currentVersion: "1.01",
    newVersion: undefined,

    currentDisplayVersion: "1.01",
    newDisplayVersion: undefined,

    serviceWorkerDelayetAt: undefined
}

export class VersionControl {

    public async hasUnsyncedData() :Promise<boolean> { 
        const isSynced = await isAllSynced()
        return !isSynced
    }

    public async showUnsyncedDataDialog(store: any) {
        if (!(await UserProfile.GetCurrentProfile()).online) return
        const title = await Resource.getResourceText("UpdateApp.UnsyncedData.Titel")
        const content = await Resource.getResourceText("UpdateApp.UnsyncedData.Content")
        openDialog(store, {name: "UnyncedData", isOk: true, isCancel: false, titel: title , text: content, open: true, type: "info"})
    }

    public async isOnline() :Promise<boolean> {
        if ((await UserProfile.GetCurrentProfile()).online) return true

        return false
    }

    public async shopUpdateDialog(store: any) {       
        if (!(await UserProfile.GetCurrentProfile()).online) return
        openDialog(store,{name: Resource.getResourceUpdateAppText(ResourceText.UpdateModalDialogName) , isCancel: true, isOk: true, open: true, titel: Resource.getResourceUpdateAppText(ResourceText.UpdateAppVersionTitle) +  "-Neu-", text: Resource.getResourceUpdateAppText(ResourceText.UpdateAppVersionText), type: ModalDialogType.VersionUpdate})
    }

    public async serviceWorkerUpdateInvoked() :Promise<void> {
        await this.ensureVersionControl()
        appVersionControl.isServiceWorkerUpdateAvailable = true
        appVersionControl.serviceWorkerUpdatedAt = ConvertDateToIsoString(new Date())
        appVersionControl.serviceWorkerDelayetAt = undefined
        appVersionControl.serviceWorkerActivatedAt = undefined
        appVersionControl.isServiceWorkerUpdateAvailable = true
        await this.saveVersionControl()
    }

    public async serviceWorkerActivateInvoked() :Promise<void> {
        await this.ensureVersionControl()
        appVersionControl.serviceWorkerActivatedAt = ConvertDateToIsoString(new Date())
        appVersionControl.isServiceWorkerUpdateAvailable = false
        appVersionControl.serviceWorkerDelayetAt = undefined
        appVersionControl.currentVersion = appVersionControl.newVersion
        appVersionControl.currentDisplayVersion = appVersionControl.newDisplayVersion
        appVersionControl.newVersion = undefined
        appVersionControl.newDisplayVersion = undefined
        await this.saveVersionControl()
    }

    public async isNewVersionPending() :Promise<boolean> {
        await this.ensureVersionControl()
        if ( !appVersionControl.newVersion || !appVersionControl.currentVersion || appVersionControl.currentVersion === "1.01") {
            await this.SaveVersionInfo()
            return false
        }

        if (  appVersionControl.newVersion && parseFloat(appVersionControl.newVersion) > parseFloat(appVersionControl.currentVersion ?? "0")) {
            if ( !appVersionControl.isServiceWorkerUpdateAvailable ) return false
            if (!(await UserProfile.GetCurrentProfile()).online) return false
            return true
        }
        return false
    }

    public async isUpdateDelayed() :Promise<boolean> {             
        if ( !appVersionControl.serviceWorkerDelayetAt) return false

        const delayed = ConvertUtcStringToDateTime(appVersionControl.serviceWorkerDelayetAt)
        if( GetDateDiffMinutes(delayed, new Date()) > 5 ) {
            appVersionControl.serviceWorkerDelayetAt = undefined
            await addOrReplaceRecord(Version, {id:"version", "versionLocal": JSON.parse(JSON.stringify( appVersionControl)) })
            return false
        }
        return true
    }

    public async setUpdateDelayed() :Promise<void> {
        await this.ensureVersionControl()
        appVersionControl.serviceWorkerDelayetAt = ConvertDateToIsoString(new Date())
        await this.saveVersionControl()    }


    public async getDisplayVersion() :Promise<string> {
        await this.ensureVersionControl()
        return (appVersionControl.currentDisplayVersion ?? "" )
    }

    public async isOldVersion() :Promise<boolean> {
        try {
            const temp  =  await getRecord(Version, "version")
            if ( !temp || !temp.versionLocal) {
                return true
            }
        } catch( e ) {
            return true
        }
        return false
    }

    public async SaveVersionInfo() {
        try {

            const temp = (await  ComboBoxDataSource.GetValue("SystemParameter", "AppVersion")) ?? ""
            if ( !temp ) return
    
            const rawResult = temp.split(",")
            if ( rawResult.length != 2 ) return
            if( !rawResult[0] || !rawResult[1] ) return
    
            await this.ensureVersionControl()
            if( parseFloat(rawResult[0]) < parseFloat(appVersionControl.currentVersion ?? "0") ) return
    
            appVersionControl.newVersion = rawResult[0]
            appVersionControl.newDisplayVersion = rawResult[1]
    
    
            if ( appVersionControl.currentVersion === "1.01") {
                appVersionControl.currentVersion = appVersionControl.newVersion
                appVersionControl.currentDisplayVersion = appVersionControl.newDisplayVersion
            }
        
    
    
            await this.saveVersionControl()    
        } catch( e ) {
            Out.noOperation("")
        }    
    }


    // ************************* Private Methods *************************

    private async ensureVersionControl() : Promise<void> { 
        try {
            const temp  =  await getRecord(Version, "version")
            if ( temp && temp.versionLocal) {
                this.mapAppVersion( temp.versionLocal as AppVersion )
            }
        } catch( e ) {
            Out.noOperation("")
        }

        await this.saveVersionControl()
    }

    private async saveVersionControl() : Promise<void> {
        try {
            await addOrReplaceRecord(Version, {id:"version", "versionLocal": JSON.parse(JSON.stringify( appVersionControl)) })
        } catch( e ) {
            Out.noOperation("")
        }
    }

    private mapAppVersion( version: AppVersion) {
        appVersionControl.isServiceWorkerUpdateAvailable = version.isServiceWorkerUpdateAvailable
        appVersionControl.versionUpdatedAt = version.versionUpdatedAt
        appVersionControl.serviceWorkerUpdatedAt = version.serviceWorkerUpdatedAt
        appVersionControl.serviceWorkerActivatedAt = version.serviceWorkerActivatedAt
        appVersionControl.currentVersion = version.currentVersion
        appVersionControl.newVersion = version.newVersion
        appVersionControl.newDisplayVersion = version.newDisplayVersion
        appVersionControl.currentDisplayVersion = version.currentDisplayVersion
        appVersionControl.serviceWorkerDelayetAt = version.serviceWorkerDelayetAt
    }



}