import { AppStateTable, addOrReplaceRecord, deleteRecord, getRecord } from "./infrastructure/database/asyncDb"
import { Out } from "./infrastructure/frontent/clientMessage"

export type WebShopStartedFrom = "Fishing" | "Hunting" | "Shop" | "None"

export type AppStateLocal = {
    WebShopStart: WebShopStartedFrom
}

export class AppState {

    private static state: AppStateLocal = {WebShopStart: "None"}

    public static  getAppStateSync() : AppStateLocal {
        return AppState.state
    }

    public static async getAppState() : Promise<AppStateLocal> {
        //Out.noOperation("")
        return await AppState.LoadAppStateFromLocalDb()
    }

    public static async saveWarenkorbStart(start: WebShopStartedFrom) : Promise<AppStateLocal> {
        AppState.state.WebShopStart = start
        await AppState.SaveAppStateToLocalDb()
        return AppState.state
    }


    private static async LoadAppStateFromLocalDb() : Promise<AppStateLocal> {
        const data =  await getRecord(AppStateTable, "appState")
        if ( data) {
            if ( data.appStateLocal) {
                if (data.appStateLocal.WebShopStart && data.appStateLocal.WebShopStart.length > 0 ) {
                    AppState.state = data.appStateLocal as AppStateLocal
                    return AppState.state
                }
            }
        }
        return AppState.state
    }

    private static async SaveAppStateToLocalDb() {
        await AppState.DeleteLocalDbAppState()
        if (AppState.state && AppState.state?.WebShopStart && AppState.state?.WebShopStart.length > 0) {

            await addOrReplaceRecord(AppStateTable, {id:"appState", "appStateLocal": JSON.parse(JSON.stringify( AppState.state)) })
        }
    }

    private static async DeleteLocalDbAppState() {
        try {
            await deleteRecord(AppStateTable, "appState")
        } catch(e) {
            Out.error("DeleteLocalAppState")
        }
    }
}