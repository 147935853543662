import { ComboBoxDataSource } from "@/components/Shared/Dynamicform/ComboBoxDataSource";
import { Identity } from "../backend/userIdentity";
import { UserProfile } from "../backend/userProfile";
import { TierartZusatzRecord, deleteDatabase, deleteRecord, deleteUnsyncedDatabase, openDatabase, wildbookTable } from "../database/asyncDb";
import { LoaderStateServiceForRoot } from "../observables/loaderState";
import { isAllSynced } from "../serviceworker-related/Sync";
import { OfflineForms } from "@/components/Shared/Dynamicform/DynamicFormPreload";
import { AsyncRestClient } from "../backend/async/AsyncRestClient";
import { ObjectSearchDataSource } from "@/components/Shared/Dynamicform/ObjectSearchDataSource";
import { TierartRules } from "../serviceworker-related/TierartZusatzService";
import { DynamicGridRequest, DynamicGridRow } from "@/components/Shared/dynamicList/DynamicListTypes";
import { getDynamicGridFromApi } from "../serviceworker-related/DynamicGridServiceWorker";
import { Out } from "../frontent/clientMessage";
import { Resource } from "../resource/resource";
import { RefreshServerObjects } from "../resource/RefreshServerObjects";
import { loadWildBookTable } from "../serviceworker-related/LocalWildBookDataAccess";
import { reSyncWildBookApi } from "../serviceworker-related/ReSyncWildBook";
import { VersionControl } from "./VersionControl";
import { AsyncDbVersion } from "./asyncDbVersion";
import { version } from "vue";

export class ReorganizeDatabaseAfterChange {

    private static _loaderState: LoaderStateServiceForRoot


    public static async updateToNewVersion() {
      AsyncDbVersion.ensureCurrentDbVersion()
      const version = new VersionControl()
      if (await version.isOldVersion()) { 
        await this.updateToNewVersion_Old()
        return
      }

      await this.updateToNewVersion_New()
    }

    public static async updateToNewVersion_New() {
      if ( !this._loaderState) ReorganizeDatabaseAfterChange._loaderState = LoaderStateServiceForRoot.getLoaderStateService()
      ReorganizeDatabaseAfterChange._loaderState.changeLoaderState({isInProgress: true})

      const isUser = Identity.isUserRole()
      await ReorganizeDatabaseAfterChange.deleteTables()


      await ReorganizeDatabaseAfterChange.removeAllStores()
      await RefreshServerObjects.resetAll()

      await AsyncDbVersion.ensureCurrentDbVersion()
      AsyncDbVersion.setUpdateDatabaseVersion(true)
      try {
        await openDatabase()
      } catch( e ) {
        Out.noOperation(e)
      }
      AsyncDbVersion.setUpdateDatabaseVersion(false)
      await AsyncDbVersion.ensureCurrentDbVersion()

      if ( isUser) {
        await ReorganizeDatabaseAfterChange.loadWhenSynced()
      } else {
         await ReorganizeDatabaseAfterChange.loadWhenNotLoggedIn()
      }

      const version = new VersionControl()
      await version.SaveVersionInfo()

      //ReorganizeDatabaseAfterChange._loaderState.changeLoaderState({isInProgress: false})
  }

   

    public static async updateToNewVersion_Old() {
        if ( !this._loaderState) ReorganizeDatabaseAfterChange._loaderState = LoaderStateServiceForRoot.getLoaderStateService()
        ReorganizeDatabaseAfterChange._loaderState.changeLoaderState({isInProgress: true})

        const isUser = Identity.isUserRole()
        const isSynced = await isAllSynced()

        await ReorganizeDatabaseAfterChange.deleteTables()

  
        if ( isSynced) {
            await ReorganizeDatabaseAfterChange.removeAllStores()
            await RefreshServerObjects.resetAll()
        } else {
            await ReorganizeDatabaseAfterChange.removeSyncedStores()
            await RefreshServerObjects.resetUserDefined()
        }

        await AsyncDbVersion.ensureCurrentDbVersion()
        AsyncDbVersion.setUpdateDatabaseVersion(true)
        try {
          await openDatabase()
        } catch( e ) {
          Out.noOperation(e)
          Out.noOperation(e)
        }
        AsyncDbVersion.setUpdateDatabaseVersion(false)
        await AsyncDbVersion.ensureCurrentDbVersion()

        if ( isUser) {
            if ( isSynced) {
                await ReorganizeDatabaseAfterChange.loadWhenSynced()
            } else {
                await ReorganizeDatabaseAfterChange.loadWhenNotSynced()
            }
        } else {
           await ReorganizeDatabaseAfterChange.loadWhenNotLoggedIn()
        }


        const version = new VersionControl()
        await version.SaveVersionInfo()

        //ReorganizeDatabaseAfterChange._loaderState.changeLoaderState({isInProgress: false})
    }

    private static async removeAllStores() {
      try {
        await deleteDatabase()
      } catch( e ) {
        Out.noOperation(e)
      }
    }

    
    private static async removeSyncedStores() {
      try {
        await deleteUnsyncedDatabase()
      } catch( e ) {
        Out.noOperation(e)
      }
    }


    private static async deleteTables()
    {

      try {
        await deleteRecord(TierartZusatzRecord, "tierartZusatzData")
      } catch( e ) {
        Out.noOperation(e)
        Out.noOperation(e)
      }

      try {


          const dataRows = await loadWildBookTable() as any
          if ( dataRows === undefined ) return undefined
          for(const item of dataRows as DynamicGridRow[]) {
              await deleteRecord(wildbookTable , item.id ?? "")            
          }
      } catch( e ) {
        Out.noOperation(e)
      }
    }

    private static async loadWhenNotLoggedIn() {
        await ReorganizeDatabaseAfterChange.loadResources()
        
        await ComboBoxDataSource.initNamedLists(true)
        await OfflineForms.initOfflineForms()

        //await this.deleteTables()

    }


    private static async loadWhenNotSynced() {
        await ReorganizeDatabaseAfterChange.loadResources()
        
        await ComboBoxDataSource.initNamedLists(true)
        await OfflineForms.initOfflineForms()
         
        const identity = Identity.getIdentityViaCookie()
        const userProfile: UserProfile = new UserProfile()
        await userProfile.ChangeLastLoginDate() 

        //await this.deleteTables()

        if ( identity !== undefined) {
          try {
            await userProfile.ChangeUserId(identity.getValue().UserId)
            await userProfile.ChangeRoles(identity.getValue().Roles)

            await ComboBoxDataSource.initUserNamedList()
            await ObjectSearchDataSource.initUserNamedList()
            await TierartRules.initRules()

            
          } catch( e ) {
            Out.noOperation(e)
          }
        }     
    }
   
    
      

    private static async loadWhenSynced() {
        await ReorganizeDatabaseAfterChange.loadResources()
        
        await ComboBoxDataSource.initNamedLists(false)
        await OfflineForms.initOfflineForms()
         
        const identity = Identity.getIdentityViaCookie()
        const userProfile: UserProfile = new UserProfile()
        await userProfile.ChangeLastLoginDate() 

        if ( identity !== undefined) {
          try {

           
            await ReorganizeDatabaseAfterChange.loadUserTables()
            
            await userProfile.ChangeUserId(identity.getValue().UserId)
            await userProfile.ChangeRoles(identity.getValue().Roles)

            await ComboBoxDataSource.initUserNamedList()
            await ObjectSearchDataSource.initUserNamedList()
            await TierartRules.initRules()

            
          } catch( e ) {
            Out.noOperation(e)
          }
        }     
    }

    private static async loadUserTables() {
      try {
        const request1: DynamicGridRequest = {
          userId: Identity.getIdentity().getValue().UserId,
          viewId: "fishBookEntriesWithDetail",
          activeData: true,
          pageSize: 1,
          revierNr: "",
          routeName: "FishBook",
          detailRouteName: "FishbookDetailRecord",
          page: "FischbuchEintragPachtDaten",
          form: "FischbuchEintragPacht",
          language: (await UserProfile.GetClientSettingsAsync()).language,
          detailId: 'FishbookDetailRecord',
          detailList: true,
          allRecords: true,
          lastRefreshDate: await RefreshServerObjects.getLastGridFormRefreshDate(),
          lastViewIdRefreshState: await RefreshServerObjects.getLastGridFishBookRefreshDate()
        }
        const gridObject1 = await getDynamicGridFromApi(request1, true)
      } catch( e ) {
        Out.noOperation(e)
      }

      try {
        const request2: DynamicGridRequest = {
          userId: Identity.getIdentity().getValue().UserId,
          viewId: "personProductsWithDetail",
          activeData: true,
          pageSize: 1,
          revierNr: "",
          routeName: "",
          detailRouteName: "",
          page: "1",
          language: (await UserProfile.GetClientSettingsAsync()).language,
          detailId: '',
          detailList: false,
          allRecords: true,
          lastRefreshDate: await RefreshServerObjects.getLastGridFormRefreshDate(),
          lastViewIdRefreshState: await RefreshServerObjects.getLastGridProductsRefreshDate()
        }
        const gridObject2 = await getDynamicGridFromApi(request2, true)
      } catch( e ) {
        Out.noOperation(e)
      }

      try {
        const request3: DynamicGridRequest = {
          userId: Identity.getIdentity().getValue().UserId,
          viewId: "wildBookEntriesWithDetail",
          activeData: true,
          pageSize: 1,
          revierNr: "",
          routeName: "",
          detailRouteName: "",
          page: "1",
          language: (await UserProfile.GetClientSettingsAsync()).language,
          detailId: '',
          detailList: false,
          allRecords: true,
          lastRefreshDate: await RefreshServerObjects.getLastGridFormRefreshDate(),
          lastViewIdRefreshState: await RefreshServerObjects.getLastGridWildBookRefreshDate()
          
          }
          const gridObject3 = await getDynamicGridFromApi(request3, true)
        } catch( e ) {
          Out.noOperation(e)
      }



      try {
        const wildBook = await reSyncWildBookApi()
      } catch( e ) {
        Out.noOperation(e)
      }  
    }
  

      private static async  loadResources() {
        const loaderState = LoaderStateServiceForRoot.getLoaderStateService()
        loaderState.changeLoaderState({isInProgress: true})
        await Resource.loadResources("ReorganizeDatabaseAfterChange");
        await Resource.loadSyncWrapper()
        //loaderState.changeLoaderState({isInProgress: false})
      }


}